/*
Theme Name: Designers Theme
Creator: Ben Sanders
URL: Bensanders.me
License: Creative Commons License
*/
// Variables
$background-bg: color(srgb 0.933 0.933 0.933);
$fonts: "Roboto Mono", monospace !important;
$alert-base: #c6538c;
$border-dark: rgba(#cc8954,0.88);
$linkHover:	#FFD486;
$linkColor:	#0074D9;

html,body {
	font-family: $fonts;
	padding: 0;
	margin: 0;
	vertical-align: baseline;
}

.alert {
	border: 1px solid $border-dark;
}

a {
	color: $linkColor;
	text-decoration: none;
}

a.homepage-link {
	text-align: center;
}
	
a:hover {
	text-decoration: underline;
}

.homepage-link {
	margin: 0 1em 1em 0;
}

.home-social-link a {
	color:black;
}

.fa-brands { color: black; }
.fa-solid { color: black; }

.active {
	text-decoration: underline;
}

#bens-resume .content h2 { color: black; }

.pfp {
	width: 106px;
	height: 100%;
	background: #32557f;
	position: relative;
	border-radius: 50%;
	border: 2.5px solid black;
}

.panel {
	padding-top: 15%;
	padding-left: 1.5em;
	padding-right: 1.5em;
	position: relative;
	text-align: center;
	max-width: 620px;
	margin: 0 auto;
}

.panel-resume {
	padding-top: 0%;
	padding-right: 1.5em;
	padding-left: 1.5em;
	position: relative;
	text-align: left;
	max-width: 620px;
	margin: 0 auto;
}

.panel-about {
	padding-top: 0%;
	position: relative;
	text-align: left;
	max-width: 620px;
	margin: 0 auto;
}

.panel-about h1 {
	text-align: center;
}

.panel-resume ul li { list-style: inherit; }

.panel p { text-align: left; }

.post-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 1em;	
}

.three-images {
	flex-direction: row;
	-webkit-box-orient: horizontal;
}

.post-img-container {
	display: block;
	position: relative;
	width: 100%;
}

#post-grid img {
	width:100%;
}

ul li {
	list-style: none;
}

nav {
	max-width: 100%;
	background-color: #333;
}

ul#posts li {
	line-height: 1.75em;
}

ul#pages {
	margin: 0;
	padding: 0;
	display: table-cell;
	overflow: hidden;
	background-color: #333;
}

ul#pages li {
	display: inline;
	float:left;
}

ul#pages li a {
	display: block;
	color: white;
	text-align: center;
	padding: 14px 16px;
	text-decoration: none;
}

ul#pages li a:hover {
	background-color: #111;
}

#post {
	width: 100%;
	overflow: hidden;
	padding: 1em 0 0;
	margin: 0 auto	4em;
}

#post.pico {
	margin-bottom: 1.5em;
}

#post.pic .entry-title {
	margin: 1em auto 1em;
}

.featuredImage {
	width: 100%;
}

.meta {
	font-size: small;
}

@media only screen and (min-width: 45em) {
	#post.pico .entry-title {
		margin: 1em auto 1em;
	}	
}

artice, aside, details, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

p {
	width: 100%;
	max-width: 100%;
	line-height: 1.6em;
}

footer {
	text-align: center;
	font-size: .7em;
	position: relative;
	max-height: 620px;
	margin: 0 auto;
}

#content-nav {
	display: grid;
	grid-template-columns: 1fr 1fr;
	row-gap: 20px;
	column-gap: 20px;
	margin-top: 3em;
}

#content-nav a { list-style: none; text-decoration: none; }
#content-nav a:hover { list-style-type: none; text-decoration: underline; }
#content-nav a svg {
	--svg-color: rgb(146, 155, 167);
	fill: var(--svg-color);
	width: 62px;
	height: 62px;
	padding: 1em;
	opacity: .8;
}

#content-nav a .content {
	padding: 0.8em 1em 1.15em;
}

#content-nav a .content .heading {
	font-weight: 600;
	font-size: 1.1em;
	color: rgba(112,118,126,.86);
	line-height: 1.35;
	margin-bottom: 0.2rem;
}

#content-nav a .content .desc {
	color: rgba(112,118,126,.55);
	font-size: 1.05rem;
	font-weight: 500;
	line-height: 1.45;
}

#content-nav a.featured {
	grid-column: span 2;
	line-height: 0;
}

#content-nav a.featured img {
	width: 100%;
	margin: 0!important;
	border-top-left-radius: 16px;
	border-top-right-radius: 16px;
}

#content-nav a.featured .content-wrap {
	display: grid;
	grid-template-columns: 96px 1fr;
	column-gap: 0;
	align-items: start;
	align-content: center;
	justify-content: start;
	padding: 0.9em 0 1.1em;
}

#content-nav a.featured svg {
	padding: 0;
	justify-self: center;
	align-self: center;
}

#content-nav a.featured .content-wrap .content {
	align-self: center;
	padding: 0;
}

#content-nav a .content .heading {
	font-weight: 600;
	font-size: 1.1em;
	color: rgba(112,118,126,.86);
	line-height: 1.35;
	margin-bottom: 0.2rem;
}

#content-nav a .content .desc {
	color: rgba(112,118,126,.55);
	font-size: 1.05rem;
	font-weight: 500;
	line-height: 1.45;
}